<template>
  <div>
    <b-overlay>
      <b-card
        title="Generate new Keys"
      >
        <b-form-group
          label="Amount"
        >
          <vue-slider
            v-model="amount"
            :min="1"
            :max="30"
            :tooltip="'always'"
            :direction="'ltr'"
            :drag-on-click="true"
          />
        </b-form-group>
        <b-form-group
          label="Duration"
        >
          <vue-slider
            v-model="durationSlider"
            :min="1"
            :max="30"
            :tooltip="'always'"
            :direction="'ltr'"
            :drag-on-click="true"
          />
        </b-form-group>
        <b-form-group
          label="Viewers"
        >
          <b-form-input
            v-model="viewerAmountSlider"
          />
          <vue-slider
            v-model="viewerAmountSlider"
            :min="1"
            :max="2000"
            :tooltip="'always'"
            :direction="'ltr'"
            :drag-on-click="true"
          />
        </b-form-group>
        <b-form-group
          label="VodViews"
        >
          <vue-slider
            v-model="vodViewSlider"
            :min="1"
            :max="5000"
            :tooltip="'always'"
            :direction="'ltr'"
            :drag-on-click="true"
          />
        </b-form-group>
        <b-form-group
          label="Follower"
        >
          <vue-slider
            v-model="followerSlider"
            label="Amount"
            :min="1"
            :max="3000"
            :tooltip="'always'"
            :direction="'ltr'"
            :drag-on-click="true"
          />
          <b-form-group
            label="FirstID Number"
          >
            <vue-slider
              v-model="idNumber"
              label="Amount"
              :min="1"
              :max="9"
              :tooltip="'always'"
              :direction="'ltr'"
              :drag-on-click="true"
            />
          </b-form-group>
          <b-form-group
            label="Pricing"
          >
            <b-form-input
              v-model="price"
            />
          </b-form-group>

          <b-row>
            <b-col md="6">
              <b-form-group
                label="Last-Key"
              />
              <b-form-group
                :label="lastLicense"
              />
            </b-col>
            <b-col>
              CurrentName: {{ currentPackageName }}
            </b-col>

          </b-row>
          <b-row>
            <b-col
              md="6"
              class="mt-1 mb-5"
            >
              <b-list-group>
                <b-list-group-item
                  v-for="item in LicenseList"
                  :key="item"
                >
                  {{ item }}
                </b-list-group-item>
              </b-list-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              Presets
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-button
                v-if="show"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="danger"
                @click="setStarterLicense(7)"
              >
                Starter-Week
              </b-button>
            </b-col>
            <b-col>
              <b-button
                v-if="show"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="setStarterLicense(30)"
              >
                Starter-Month
              </b-button>
            </b-col>
            <b-col>
              <b-button
                v-if="show"

                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="danger"
                @click="setHeadstarterLicense(7)"
              >
                HeadStarter-Week
              </b-button>
            </b-col>
            <b-col>
              <b-button
                v-if="show"

                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="setHeadstarterLicense(30)"
              >
                HeadStarter-Month
              </b-button>
            </b-col>
            <b-col>
              <b-button
                v-if="show"

                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="danger"
                @click="setProLicense(7)"
              >
                Pro-Week
              </b-button>
            </b-col>
            <b-col>
              <b-button
                v-if="show"

                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="setProLicense(30)"
              >
                Pro-Month
              </b-button>
            </b-col>
            <b-col>
              <b-button
                v-if="show"

                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="setCustomLicense"
              >
                Pro-Custom
              </b-button>
            </b-col>
            <!-- toggle button -->
          </b-row>
          <b-row class="mt-3">
            <b-col>
              Generate
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                block
                @click="createTrialLicense(false)"
              >
                Trial
              </b-button>
            </b-col>
            <b-col cols="2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="warning"
                block
                @click="createTrialLicense(true)"
              >
                MultiUseTrial
              </b-button>
            </b-col>
            <b-col>
              <b-button
                variant="success"
                block
                size="x-large"
                @click="createLicenses"
              >
                Generate
              </b-button>
            </b-col>
          </b-row>
        </b-form-group></b-card>
    </b-overlay>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components,no-plusplus */
import {
  BFormCheckbox, BOverlay, BCardText, BCard, BFormInput, BButton, BFormGroup, BCol, BRow, BIcon, BIconCash, BFormValidFeedback, BFormInvalidFeedback, BListGroup,
} from 'bootstrap-vue'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import { getUserData } from '@/auth/utils'
import VueSlider from 'vue-slider-component'
// import firebase from 'firebase'

export default {
  components: {
    BListGroup,
    BCol,
    BOverlay,
    BRow,
    BFormCheckbox,
    BCard,
    BCardText,
    BFormValidFeedback,
    BFormInvalidFeedback,
    VueSlider,
    BFormInput,
    BButton,
    BFormGroup,
    // eslint-disable-next-line vue/no-unused-components
    BIconCash,
    BIcon,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      amount: 10,
      price: 0,
      durationSlider: 7,
      viewerAmountSlider: 50,
      vodViewSlider: 300,
      currentPackageName: 'Starter',
      followerSlider: 50,
      lastLicense: 'xxxx-xxxx-xxxx',
      minutes: 0,
      idNumber: 3,
      seconds: 15,
      max: 20,
      valueslider: 15,
      code: null,
      show: false,
      twitch: '',
      active: false,
      autostart: false,
      LicenseList: [],
      userList: ['towa5MUabmfPHflpRoxMVCrmDaj2', '4Mh9ehv8nrYN8ABkLfinQI7I2yH3', 'MBeqOMpykEemj8xw3ApbDpolpb12'],
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = 'rtl'
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = 'ltr'
      return this.dir
    },
  },
  watch: {
    valueslider() {
      if (this.valueslider > this.max) {
        this.valueslider = this.max
      }
    },
  },
  mounted() {
    const userData = getUserData()
    if (this.userList.includes(userData.id)) {
      this.show = true
    }
  },
  methods: {
    afterEachMethod() {
      this.copyURL(this.lastLicense)
      this.LicenseList.push(this.lastLicense)
    },
    async copyURL(mytext) {
      try {
        await navigator.clipboard.writeText(mytext)
      } catch ($e) {
        alert('Cannot copy')
      }
    },
    onShown() {
      // Focus the cancel button when the overlay is showing
    },
    onHidden() {
      // Focus the show button when the overlay is removed
    },
    licenseID(number) {
      const seq1 = (Math.floor(Math.random() * 1000) + 1000).toString().substring(1)
      const seq2 = (Math.floor(Math.random() * 10000) + 10000).toString().substring(1)
      const seq3 = (Math.floor(Math.random() * 10000) + 10000).toString().substring(1)
      return `${number}${seq1}-${seq2}-${seq3}`
    },
    createLicenses() {
      const userData = getUserData()
      if (this.userList.includes(userData.id)) {
        for (let i = 0; i < this.amount; i++) {
          const id = this.licenseID(this.idNumber)
          const sx = this.$firebase.firestore()
          sx.collection('License')
            .doc(id)
            .get()
            .then(docSnapshot => {
              if (docSnapshot.exists) {
                this.$swal({
                  title: 'Errors1!',
                  text: ' Something went wrong!  Maybe the License already exists?',
                  icon: 'error',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              } else {
                sx.collection('License')
                  .doc(id)
                  .set({
                    createdUserID: userData.id,
                    Credits: 10,
                    Cliptokens: 10,
                    Follower: this.followerSlider,
                    ID: id,
                    Package: this.currentPackageName,
                    Used: false,
                    Viewer: this.viewerAmountSlider,
                    Duration: this.durationSlider,
                    price: this.price,
                  })
                  .then(() => {
                    this.lastLicense = id
                    this.afterEachMethod()
                    this.$swal({
                      title: 'Succeed!',
                      text: ` You just created Trial Package ${id}!`,
                      icon: 'success',
                      customClass: {
                        confirmButton: 'btn btn-primary',
                      },
                      buttonsStyling: false,
                    })
                  })
                  .catch(error => {
                    console.log(error)
                    this.$swal({
                      title: 'Errors2!',
                      text: ' Something went wrong!  Maybe the License already exists?',
                      icon: 'error',
                      customClass: {
                        confirmButton: 'btn btn-primary',
                      },
                      buttonsStyling: false,
                    })
                  })
              }
            })
        }
      }
    },
    // top start
    createTrialLicense(multiuse) {
      // console.log(`user id: ${firebase.auth().currentUser.uid}`)
      // eslint-disable-next-line no-plusplus
      // eslint-disable-next-line no-plusplus
      const userData = getUserData()
      for (let index = 0; index < this.amount; index++) {
        if (true) {
          const id = this.licenseID(1)
          const sx = this.$firebase.firestore()
          sx.collection('License')
            .doc(id)
            .get()
            .then(docSnapshot => {
              if (docSnapshot.exists) {
                this.$swal({
                  title: 'Errors1!',
                  text: ' Something went wrong!  Maybe the License already exists?',
                  icon: 'error',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              } else {
                sx.collection('License')
                  .doc(id)
                  .set({
                    Credits: 3600,
                    multiuse,
                    Cliptokens: 2,
                    Follower: 1,
                    createdUserID: userData.id,
                    ID: id,
                    Package: 'Trial',
                    Used: false,
                    Viewer: 20,
                    Duration: 1,
                  })
                  .then(() => {
                    this.lastLicense = id
                    this.afterEachMethod()
                    this.$swal({
                      title: 'Succeed!',
                      text: ` You just created Trial Package ${id}!`,
                      icon: 'success',
                      customClass: {
                        confirmButton: 'btn btn-primary',
                      },
                      buttonsStyling: false,
                    })
                  })
                  .catch(error => {
                    console.log(error)
                    this.$swal({
                      title: 'Errors2!',
                      text: ' Something went wrong!  Maybe the License already exists?',
                      icon: 'error',
                      customClass: {
                        confirmButton: 'btn btn-primary',
                      },
                      buttonsStyling: false,
                    })
                  })
              }
            })
        }
      }

      // console.log(this.twitch)
    },
    setStarterLicense(duration) {
      this.idNumber = (duration < 29) ? 2 : 3

      this.viewerAmountSlider = 15
      this.vodViewSlider = 300
      this.durationSlider = duration
      this.followerSlider = (duration < 29) ? 10 : 40
      this.currentPackageName = 'Starter'
    },
    setHeadstarterLicense(duration) {
      this.idNumber = (duration < 29) ? 4 : 5
      this.viewerAmountSlider = 50
      this.vodViewSlider = 1000
      this.durationSlider = duration
      this.followerSlider = (duration < 29) ? 40 : 150
      this.currentPackageName = 'Headstarter'
    },
    setProLicense(duration) {
      this.idNumber = (duration < 29) ? 6 : 7
      this.viewerAmountSlider = 100
      this.vodViewSlider = 1000
      this.durationSlider = duration
      this.followerSlider = (duration < 29) ? 75 : 300
      this.currentPackageName = 'Pro'
    },
    setCustomLicense() {
      this.idNumber = 9
      this.viewerAmountSlider = 100
      this.vodViewSlider = 3000
      this.durationSlider = 30
      this.followerSlider = 300
      this.currentPackageName = 'Custom'
    },
    createStandardMonthLicense() {
      for (let index = 0; index < this.amount; index++) {
        // console.log(`user id: ${firebase.auth().currentUser.uid}`)
        this.createStandardLicense('month')
      }
      // console.log(this.twitch)
    },
    createStarterWeekLicense() {
      for (let index = 0; index < this.amount; index++) {
        // console.log(`user id: ${firebase.auth().currentUser.uid}`)
        this.createStarterLicense('week')
      }
      // console.log(this.twitch)
    },
    createStarterMonthLicense() {
      for (let index = 0; index < this.amount; index++) {
        // console.log(`user id: ${firebase.auth().currentUser.uid}`)
        this.createStarterLicense('month')
      }
      // console.log(this.twitch)
    },
    createPartnerMonthLicense() {
      for (let index = 0; index < this.amount; index++) {
        this.createPartnerLicense('month')
      }
    },
    createPartnerWeekLicense() {
      for (let index = 0; index < this.amount; index++) {
        this.createPartnerLicense('week')
      }
    },

    createStarterLicense(length) {
      // console.log(`user id: ${firebase.auth().currentUser.uid}`)
      const userData = getUserData()
      if (userData.id === 'towa5MUabmfPHflpRoxMVCrmDaj2' || userData.id === 'd3guBeDNhMf21P1bDCougbIWQQS2') {
        let duration
        let id
        let credits
        let follower
        if (length === 'week') {
          duration = 7
          credits = 20000
          follower = 10
          id = this.licenseID(2)
        } else if (length === 'month') {
          duration = 30
          credits = 80000
          follower = 40
          id = this.licenseID(3)
        }
        const sx = this.$firebase.firestore()
        sx.collection('License')
          .doc(id)
          .get()
          .then(docSnapshot => {
            if (docSnapshot.exists) {
              this.$swal({
                title: 'Errors1!',
                text: ' Something went wrong!  Maybe the License already exists?',
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            } else {
              sx.collection('License')
                .doc(id)
                .set({
                  Credits: credits,
                  Cliptokens: 20,
                  Follower: follower,
                  ID: id,
                  Package: 'Starter',
                  Used: false,
                  Viewer: 15,
                  Duration: duration,
                })
                .then(() => {
                  this.lastLicense = id
                  this.afterEachMethod()
                  this.$swal({
                    title: 'Succeed!',
                    text: ` You just created Trial Package ${id}!`,
                    icon: 'success',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  })
                })
                .catch(error => {
                  console.log(error)
                  this.$swal({
                    title: 'Errors2!',
                    text: ' Something went wrong!  Maybe the License already exists?',
                    icon: 'error',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  })
                })
            }
          })
      }
      // console.log(this.twitch)
    },
    createStandardLicense(length) {
      // console.log(`user id: ${firebase.auth().currentUser.uid}`)
      const userData = getUserData()
      if (userData.id === 'towa5MUabmfPHflpRoxMVCrmDaj2' || userData.id === 'd3guBeDNhMf21P1bDCougbIWQQS2') {
        let duration
        let id
        let credits
        let follower
        if (length === 'week') {
          duration = 7
          credits = 65000
          follower = 40
          id = this.licenseID(4)
        } else if (length === 'month') {
          duration = 30
          credits = 270000
          follower = 150
          id = this.licenseID(5)
        }
        const sx = this.$firebase.firestore()
        sx.collection('License')
          .doc(id)
          .get()
          .then(docSnapshot => {
            if (docSnapshot.exists) {
              this.$swal({
                title: 'Errors1!',
                text: ' Something went wrong!  Maybe the License already exists?',
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            } else {
              sx.collection('License')
                .doc(id)
                .set({
                  Credits: credits,
                  Cliptokens: 50,
                  Follower: follower,
                  ID: id,
                  Package: 'Headstarter',
                  Used: false,
                  Viewer: 50,
                  Duration: duration,
                })
                .then(() => {
                  this.lastLicense = id
                  this.afterEachMethod()
                  this.$swal({
                    title: 'Succeed!',
                    text: ` You just created Headstarter Package ${id}!`,
                    icon: 'success',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  })
                })
                .catch(error => {
                  console.log(error)
                  this.$swal({
                    title: 'Errors2!',
                    text: ' Something went wrong!  Maybe the License already exists?',
                    icon: 'error',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  })
                })
            }
          })
      }
      // console.log(this.twitch)
    },
    createPartnerLicense(length) {
      // console.log(`user id: ${firebase.auth().currentUser.uid}`)
      const userData = getUserData()
      if (userData.id === 'towa5MUabmfPHflpRoxMVCrmDaj2' || userData.id === 'd3guBeDNhMf21P1bDCougbIWQQS2') {
        let duration
        let id
        let credits
        let follower
        if (length === 'week') {
          duration = 7
          credits = 125000
          follower = 75
          id = this.licenseID(6)
        } else if (length === 'month') {
          duration = 30
          credits = 540000
          follower = 300
          id = this.licenseID(7)
        }
        const sx = this.$firebase.firestore()
        sx.collection('License')
          .doc(id)
          .get()
          .then(docSnapshot => {
            if (docSnapshot.exists) {
              this.$swal({
                title: 'Errors1!',
                text: ' Something went wrong!  Maybe the License already exists?',
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            } else {
              sx.collection('License')
                .doc(id)
                .set({
                  Credits: credits,
                  Cliptokens: 200,
                  Follower: follower,
                  ID: id,
                  Package: 'Pro',
                  Used: false,
                  Viewer: 100,
                  Duration: duration,
                })
                .then(() => {
                  this.lastLicense = id
                  this.afterEachMethod()
                  this.$swal({
                    title: 'Succeed!',
                    text: ` You just created Pro Package ${id}!`,
                    icon: 'success',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  })
                })
                .catch(error => {
                  console.log(error)
                  this.$swal({
                    title: 'Errors2!',
                    text: ' Something went wrong!  Maybe the License already exists?',
                    icon: 'error',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  })
                })
            }
          })
      }
      // console.log(this.twitch)
    },
    // top end
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-slider.scss';
</style>
